<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="2" class="pr-12">
          <h2>Sizes</h2>
        </v-col>
        <v-col class="pl-12" cols="7">
          <v-text-field
            label="Search Size Group"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>

        <v-col cols="3" align="right">
          <v-row align="center" justify="end">
            <v-btn
              class="mr-2"
              depressed
              light
              :to="{ name: 'module-eow-products' }"
            >
              <v-icon small left>mdi-arrow-left</v-icon> Back to Products
            </v-btn>
          </v-row>
        </v-col>
      </v-row>

      <v-row align="center" justify="end">
        <v-btn
          depressed
          color="warning"
          class="mr-2"
          @click="$refs.groupForm.openForm()"
        >
          Add Size Group
        </v-btn>

        <v-btn
          depressed
          color="accent"
          class="mr-2"
          @click="$refs.sizeForm.openForm()"
        >
          Add New Size
        </v-btn>
      </v-row>
    </v-container>

    <v-data-table
      :headers="tableHeaders"
      :items="groups"
      :expanded="groups"
      :items-per-page="-1"
      :disable-pagination="true"
      :hide-default-footer="true"
    >
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              x-small
              depressed
              color="blue lighten-4 blue--text"
              v-on="on"
              class="mr-2"
              @click="$refs.groupForm.openForm(item)"
            >
              <v-icon x-small>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              x-small
              depressed
              color="red lighten-4 red--text"
              v-on="on"
              @click="openDeleteGroup(item)"
            >
              <v-icon x-small>mdi-archive</v-icon>
            </v-btn>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td
          :colspan="headers.length"
          class="pl-4 pr-0"
          v-if="item.sizes.length > 0"
        >
          <v-data-table
            :items="item.sizes"
            :headers="tableHeadersChild"
            :disable-pagination="true"
            :hide-default-footer="true"
            :hide-default-header="true"
            class="my-awesome-table"
            v-sortable-data-table="{ groupId: item.id }"
            @sorted="saveOrderChild"
          >
            <template v-slot:item.sort="{ item }">
              <v-icon small class="page__grab-icon">mdi-arrow-all</v-icon>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    class="mr-2"
                    @click="$refs.sizeForm.openForm(item)"
                  >
                    <v-icon x-small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="red lighten-4 red--text"
                    v-on="on"
                    @click="openDeleteSize(item)"
                  >
                    <v-icon x-small>mdi-archive</v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </td>
      </template>
    </v-data-table>

    <SizeForm ref="sizeForm" />
    <GroupForm ref="groupForm" />
    <v-dialog v-model="deleteSize.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Delete Size</v-card-title>
        <v-card-text
          >Are you sure you wish to delete
          {{ deleteSize.size.name }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDeleteSize"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="deleteSize.loading"
            @click="saveDeleteSize"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteGroup.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Delete Group</v-card-title>
        <v-card-text
          >Are you sure you wish to delete {{ deleteGroup.name }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDeleteGroup"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="deleteGroup.loading"
            @click="saveDeleteGroup"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Sortable from "sortablejs";
import SizeForm from "./components/SizeForm";
import GroupForm from "./components/GroupForm";

export default {
  components: { SizeForm, GroupForm },

  data() {
    return {
      groupBy: [{ key: "group_id", order: "asc" }],
      searchTerm: "",
      breadcrumbs: [
        {
          text: "eow",
          disabled: false,
          to: { name: "app-dashboard", params: { id: this.$route.params.id } },
        },
        {
          text: "Products",
          disabled: false,
          exact: true,
          to: {
            name: "module-eow-products",
          },
        },
        {
          text: "Sizes",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Name", value: "name" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],

      tableHeadersChild: [
        { text: "", value: "sort", width: "20%", class: "dupa" },
        { text: "Name", value: "name", width: "20%" },
        {
          width: "60%",
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "right",
        },
      ],

      deleteGroup: {
        dialog: false,
        group: {},
        loading: false,
      },

      deleteSize: {
        dialog: false,
        size: {},
        loading: false,
      },
    };
  },

  computed: {
    groups() {
      let groups = this.$store.getters["eow/productsStore/groups"];

      if (this.searchTerm !== "") {
        groups = groups.filter((p) => {
          const name = p.name.toLowerCase();

          return name.includes(this.searchTerm.toLowerCase());
        });
      }

      return groups;
    },

    sizes() {
      let sizes = this.$store.getters["eow/productsStore/sizes"];

      return sizes;
    },
  },

  directives: {
    sortableDataTable: {
      bind(el, binding, vnode) {
        const options = {
          animation: 150,
          onUpdate: function (event) {
            event.groupId = binding.value.groupId;

            vnode.child.$emit("sorted", event);
          },
        };
        Sortable.create(el.getElementsByTagName("tbody")[0], options);
      },
    },
  },

  methods: {
    saveOrderChild(event) {
      const index = this.groups.findIndex((p) => p.id === event.groupId);

      const movedItem = this.groups[index].sizes.splice(event.oldIndex, 1)[0];
      this.groups[index].sizes.splice(event.newIndex, 0, movedItem);

      const displayOrder = this.groups[index].sizes.map((p) => {
        return p.id;
      });
      const appId = this.$route.params.id;
      const groupId = event.groupId;

      this.$store.commit("showHideLoader", true);
      this.$store
        .dispatch("eow/productsStore/updateSizesDisplayOrder", {
          appId,
          groupId,
          displayOrder,
        })
        .then(() => this.$store.commit("showHideLoader", false))
        .catch(() => this.$store.commit("showHideLoader", false));
    },

    openDeleteSize(size) {
      this.deleteSize.size = size;
      this.deleteSize.dialog = true;
    },

    resetDeleteSize() {
      this.deleteSize.dialog = false;
      this.deleteSize.size = {};
      this.deleteSize.loading = false;
    },

    saveDeleteSize() {
      this.deleteSize.loading = true;

      this.$store
        .dispatch("eow/productsStore/deleteSize", {
          appId: this.$route.params.id,
          sizeId: this.deleteSize.size.id,
        })
        .then(() => {
          this.resetDeleteSize();
        })
        .catch(() => {
          this.deleteSize.loading = false;
        });
    },

    openDeleteGroup(group) {
      this.deleteGroup.group = group;
      this.deleteGroup.dialog = true;
    },

    resetDeleteGroup() {
      this.deleteGroup.dialog = false;
      this.deleteGroup.group = {};
      this.deleteGroup.loading = false;
    },

    saveDeleteGroup() {
      this.deleteGroup.loading = true;

      this.$store
        .dispatch("eow/productsStore/deleteGroup", {
          appId: this.$route.params.id,
          groupId: this.deleteGroup.group.id,
        })
        .then(() => {
          this.resetDeleteGroup();
        })
        .catch(() => {
          this.deleteGroup.loading = false;
        });
    },
  },
};
</script>

<style>
.v-data-table__expanded.v-data-table__expanded__content {
  box-shadow: none !important;
}

.my-awesome-table tr td:first-child {
  width: 10px;
}
</style>
