<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Size</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="saveForm" method="post" id="size-form">
          <v-text-field
            label="Name *"
            v-model="fields.name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('name')"
            :error-messages="errors['name']"
          ></v-text-field>

          <v-select
            label="Group"
            v-model="fields.group"
            :items="groups"
            item-text="name"
            item-value="id"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('group')"
            :error-messages="errors['group']"
          ></v-select>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <div v-if="errorMessage" class="red--text lighten-4">
          <v-icon small class="red--text mr-2">mdi-alert-circle</v-icon
          >{{ errorMessage }}
        </div>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="size-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      fields: {
        name: null,
        group: null,

        isEditing: false,
      },

      errors: {},
      errorMessage: null,
    };
  },

  computed: {
    groups() {
      let groups = this.$store.getters["eow/productsStore/groups"];

      return groups;
    },
  },

  watch: {
    slug(value) {
      this.fields.slug = value;
    },
  },

  methods: {
    onChange(event) {
      this.fields.slug = event;
    },
    openForm: function (size = null) {
      if (size !== null) {
        this.isEditing = true;
        this.size = size;
        this.fields.isEditing = true;
        this.fields.name = size.name;

        this.fields.group = size.group_id ? size.group_id : null;
      }

      this.dialog = true;
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.fields.name = null;
      this.fields.isEditing = false;
      this.fields.group = null;

      this.errors = {};
      this.errorMessage = null;
      this.$refs.formWrapper.scrollTop = 0;
    },

    saveForm: function () {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.sizeId = this.size.id;
      }

      this.$store
        .dispatch("eow/productsStore/saveSize", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;

          if (this.errors) {
            this.errorMessage =
              "Please ensure all fields are completed correctly";

            this.$refs.formWrapper.scrollTop = 0;
          }
        });
    },
  },
};
</script>
